<template>
  <div>
    <div style="font-size: 20px">课程开展记录概览</div>
    <el-divider class="margin10"></el-divider>
    <div class="previewTopList">
      <div class="previewLi">
        <div class="top">
          总课时
          <el-tooltip
            effect="dark"
            content="该学期教学计划内所有学校的课时总和（教学计划内一节课为1课时）；"
            placement="top-start"
          >
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </div>
        <div class="bottom">
          {{ planOverview.lessonCount }}<span>课时</span>
        </div>
      </div>
      <div class="previewLi">
        <div class="top">
          实开课时
          <el-tooltip
            effect="dark"
            content="当前学期内所有学校已完成开课凭证上传的课程课时数；（一节课为1课时）"
            placement="top-start"
          >
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </div>
        <div class="bottom">
          {{ planOverview.completeLessonCount }}<span>课时</span>
        </div>
      </div>
      <div class="previewLi">
        <div class="top">
          课程开出率（平均）
          <el-tooltip
            effect="dark"
            content="算截止当前时间内所有学校的平均开出率;单个学校开出率通过比对计划课时与实开课时;"
            placement="top-start"
          >
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </div>
        <div class="bottom">{{ planOverview.nowOpenLessonRate }}</div>
      </div>
    </div>
    <div style="font-size: 20px">课程开展记录明细</div>
    <el-divider class="margin10"></el-divider>
    <!-- 发起收集任务 -->
    <!-- <el-row style="margin-bottom: 20px"></el-row> -->
    <!-- 条件 -->
    <el-row style="display: flex; margin-bottom: -20px">
      <el-form :inline="true" ref="conditionForm" :model="conditionForm">
        <el-form-item label="学年学期" class="formItemBoxStyle">
          <el-cascader
            style="width: 100%"
            v-model="sysSchoolTermIdArr"
            :options="$store.state.public_data.sysAcademicTermYearDicList"
            :props="{
              children: 'children',
            }"
            @change="sysSchoolTermIdChange"
            clearable
          ></el-cascader>
          <!-- <el-select
            @change="changeConditionForm"
            v-model="conditionForm.sysSchoolTermId"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="item in conditionList.sysSchoolTermIdList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select> -->
        </el-form-item>
        <el-form-item label="所属区县" class="formItemBoxStyle">
          <el-select
            @change="changeConditionForm"
            v-model="conditionForm.sysOrgAreaId"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="item in conditionList.sysOrgAreaIdList"
              :key="item.sysOrgAreaId"
              :label="item.name"
              :value="item.sysOrgAreaId"
            ></el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item label="学校名称" class="formItemBoxStyle">
          <el-input
            @change="changeConditionForm"
            v-model="conditionForm.sysOrgSchoolName"
          ></el-input>
        </el-form-item> -->
        <el-form-item>
          <el-button @click="inquireBtn" type="primary">查询</el-button>
          <el-button @click="resetBtn">重置</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <!-- 表格 -->
    <el-row style="margin-top: 20px">
      <el-table
        v-loading="loading"
        border
        ref="tableData"
        :data="tableData"
        tooltip-effect="dark"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
        style="width: 100%"
      >
        <el-table-column
          prop="sysOrgAreaName"
          label="所属区县"
        ></el-table-column>
        <el-table-column
          prop="sysOrgSchoolName"
          label="学校名称"
        ></el-table-column>
        <el-table-column
          prop="shouldLessonCount"
          label="应开课时"
        ></el-table-column>
        <el-table-column
          prop="waitLessonCount"
          label="待开课时"
        ></el-table-column>
        <el-table-column prop="lessonCount" label="总课时"></el-table-column>
        <el-table-column
          prop="nowOpenLessonRate"
          label="当前开出率"
        ></el-table-column>
        <el-table-column
          prop="totalOpenLessonRate"
          label="总开出率"
        ></el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button size="mini" type="text" @click="viewBtn(scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.totalSize"
      ></el-pagination>
    </el-row>
    <!-- 弹框 -->
    <el-dialog
      title="学校教学计划查看"
      :visible.sync="dialogVisible"
      width="900px"
    >
      <el-form :inline="true" :model="dialogVisibleConditionForm">
        <el-form-item label="计划名称">
          <el-input
            @change="getSchoolPlanListFunc"
            v-model="dialogVisibleConditionForm.crsExpPlanName"
          ></el-input>
        </el-form-item>
        <el-form-item label="年级">
          <el-cascader
            @change="changeSysOrgSchoolGradeId"
            v-model="sysOrgSchoolGradeIdArr"
            :options="$store.state.public_data.sysSemesterTermGradeDicList"
            :props="{
              children: 'children',
            }"
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button @click="getSchoolPlanListFunc" type="primary"
            >查询</el-button
          >
          <el-button @click="dialogVisibleResetBtn">重置</el-button>
        </el-form-item>
      </el-form>
      <el-table
        v-loading="dialogVisibleTableLoading"
        border
        ref="dialogVisibleTableData"
        :data="dialogVisibleTableData"
        tooltip-effect="dark"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
        style="width: 100%"
        height="600"
      >
        <el-table-column
          prop="crsExpPlanName"
          label="教学计划名称"
        ></el-table-column>
        <el-table-column
          prop="sysSchoolClassGradeName"
          width="180"
          label="所属年级"
        ></el-table-column>
        <el-table-column
          prop="totalOpenClassHorRate"
          width="180"
          label="所开出率"
        ></el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              @click="dialogVisibleViewBtn(scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import {
  getAccountOverview,
  getAccountCondition,
  getAccount,
  getSchoolPlanList,
} from '@/api/courseStart/startClass.js'
export default {
  name: 'startClass',
  data() {
    return {
      planOverview: {
        lessonCount: 0,
        completeLessonCount: 0,
        equipmentUseRate: '',
        nowOpenLessonRate: '',
      },
      conditionForm: {
        sysOrgSchoolName: '',
        sysOrgAreaId: '',
        sysSchoolTermId: '',
      },
      sysSchoolTermIdArr: [],
      conditionList: {
        // 所属区县
        sysOrgAreaIdList: [],
        // 学年学期id集合
        sysSchoolTermIdList: [],
      },
      loading: false,
      tableData: [],
      //分页
      page: {
        pageIndex: 1,
        pageSize: 10,
        totalSize: 0,
      },
      // 查询学校开课计划弹框
      dialogVisible: false,
      dialogVisibleTableLoading: false,
      dialogVisibleTableData: [],
      dialogVisibleConditionForm: {
        crsExpPlanName: '',
        sysOrgSchoolGradeId: 0,
        sysOrgSchoolId: 0,
        sysSchoolTermId: 0,
      },
      sysOrgSchoolGradeIdArr: [],
    }
  },
  async created() {
    const { dataArr } = this.$store.state.public_data
    await Promise.all(dataArr.map((e) => this.$store.dispatch('loadData', e)))
    // 获取学年
    this.conditionList.sysSchoolTermIdList =
      this.$store.state.public_data.sysAcademicYearDicList
  },
  mounted() {
    this.getAccountConditionFunc()
    this.getAccountOverviewFunc({})
    this.getAccountFunc({})
  },
  methods: {
    // 开课计划台账概览
    getAccountOverviewFunc(data) {
      getAccountOverview(data)
        .then((res) => {
          if (res.success) {
            let {
              lessonCount,
              completeLessonCount,
              equipmentUseRate,
              nowOpenLessonRate,
            } = res.data
            this.planOverview = {
              lessonCount,
              completeLessonCount,
              equipmentUseRate,
              nowOpenLessonRate,
            }
          } else {
          }
        })
        .catch((err) => {})
    },
    // 查询开课计划课程开展台账过滤条件
    getAccountConditionFunc() {
      getAccountCondition()
        .then((res) => {
          console.log('查询开课计划课程开展台账过滤条件', res)
          if (res.success) {
            this.conditionList.sysOrgAreaIdList = res.data.sysOrgAreaList
          }
        })
        .catch((err) => {})
    },
    // 分页查询开课计划课程开展台账
    getAccountFunc(data) {
      getAccount(this.page.pageIndex, this.page.pageSize, data)
        .then((res) => {
          console.log('分页查询开课计划课程开展台账', res)
          if (res.success) {
            this.tableData = res.data
            this.page.totalSize = res.total
          } else {
            this.$message.info(res.msg)
          }
        })
        .catch((err) => {})
    },
    //currentPage 改变时会触发
    handleCurrentChange(val) {
      this.page.pageIndex = val
      this.changeConditionForm()
    },
    //pageSize 改变时会触发
    handleSizeChange(val) {
      this.page.pageIndex = 1
      this.page.pageSize = val
      this.changeConditionForm()
    },
    sysSchoolTermIdChange(val) {
      if (!!val) {
        this.conditionForm.sysSchoolTermId = this.sysSchoolTermIdArr[1]
      } else {
        this.conditionForm.sysSchoolTermId = ''
      }
      this.changeConditionForm()
    },
    changeConditionForm() {
      let conditionObj = JSON.parse(JSON.stringify(this.conditionForm))
      // 条件修改
      for (let key in conditionObj) {
        if (conditionObj[key] === '' || conditionObj[key] === undefined) {
          delete conditionObj[key]
        }
      }
      this.getAccountFunc(conditionObj)
    },
    // 查询
    inquireBtn() {
      this.page.pageIndex = 1
      this.changeConditionForm()
    },
    // 重置
    resetBtn() {
      for (let key in this.conditionForm) {
        this.conditionForm[key] = ''
      }
      this.page.pageIndex = 1
      this.changeConditionForm()
    },
    // 查看
    async viewBtn(row) {
      // this.dialogVisibleTableData = []
      // this.sysOrgSchoolGradeIdArr = []
      // this.dialogVisibleConditionForm.sysOrgSchoolId = row.sysSchoolTermId
      // await this.getSchoolPlanListFunc()
      // this.dialogVisible = true
      this.$router.push({
        path: `/courseStart/startClassPlanView?
        sysOrgSchoolId=${row.sysOrgSchoolId}&sysOrgSchoolName=${row.sysOrgSchoolName}`,
      })
    },
    // 年级处理
    changeSysOrgSchoolGradeId(val) {
      if (val.length !== 0) {
        this.dialogVisibleConditionForm.sysOrgSchoolGradeId = val[1]
      } else {
        this.dialogVisibleConditionForm.sysOrgSchoolGradeId = ''
      }
      this.getSchoolPlanListFunc()
    },
    // 重置
    dialogVisibleResetBtn() {
      this.dialogVisibleConditionForm.crsExpPlanName = ''
      this.dialogVisibleConditionForm.sysOrgSchoolGradeId = ''
      this.sysOrgSchoolGradeIdArr = []
      this.getSchoolPlanListFunc()
    },
    async getSchoolPlanListFunc() {
      this.dialogVisibleTableLoading = true
      let conditionObj = JSON.parse(
        JSON.stringify(this.dialogVisibleConditionForm)
      )
      // 条件修改
      for (let key in conditionObj) {
        if (conditionObj[key] === '' || conditionObj[key] === undefined) {
          delete conditionObj[key]
        }
      }
      await getSchoolPlanList(conditionObj)
        .then((res) => {
          this.dialogVisibleTableLoading = false
          if (res.success) {
            this.dialogVisibleTableData = res.data
          } else {
            this.$message.info(res.msg)
          }
        })
        .catch((err) => {})
    },
    dialogVisibleViewBtn(row) {
      this.$router.push({
        path: `/safeInspect/inspectDetail?
        sysOrgSchoolId=${row.sysOrgSchoolId}`,
      })
    },
  },
  watch: {},
  filters: {
    tenantStatusFilters(key) {
      switch (key) {
        case 104:
          return `租户未被授权`
        case 102:
          return `租户授权过期`
        case 1:
          return `正常使用中`
        case 0:
          return `租户被禁用`
        default:
          return `状态码${key}`
      }
    },
    dayFilters(val) {
      // return val ? dayjs(val).format('YYYY-MM-DD HH:mm') : ''
      return val ? dayjs(val).format('YYYY-MM-DD') : ''
    },
  },
}
</script>

<style lang="scss" scoped>
.margin10 {
  margin: 10px 0 20px 0;
}
.previewTopList {
  display: flex;
  margin-left: 30px;
  margin-bottom: 20px;
}
.previewLi {
  margin-right: 50px;
  .top {
    color: #929292;
    font-size: 16px;
    i {
      cursor: pointer;
    }
  }
  .bottom {
    font-size: 30px;
    span {
      font-size: 16px;
    }
  }
}
</style>
