import { request, noTimeOutReq } from '@/utils/request.js'

// 开课计划台账概览
export function getAccountOverview (data) {
  return request({
    url: `/crs/open/plan/account/overview`,
    method: 'post',
    data
  })
}

// 查询开课计划课程开展台账过滤条件
export function getAccountCondition () {
  return request({
    url: `/crs/open/plan/account/condition`,
    method: 'post',
  })
}

// 分页查询开课计划课程开展台账
export function getAccount (pageIndex, pageSize, data) {
  return request({
    url: `/crs/open/plan/account/${pageIndex}/${pageSize}`,
    method: 'post',
    data
  })
}

// 查询学校开课计划
export function getSchoolPlanList (data) {
  return request({
    url: `/crs/open/plan/account/list/school/plan`,
    method: 'post',
    data
  })
}
